import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { navigate } from 'gatsby'

import WithoutFooter from '../layouts/withoutFooter'
import Spend1Save1Closed from '../components/ForSpendOneSaveOne/Spend1Save1Closed'
import Spend1Save1ClosedBody from '../components/ForSpendOneSaveOne/Spend1Save1ClosedBody'
import TAndC from '../components/ForSpendOneSaveOne/TAndC'
import Seo from '../components/seo'
import { parseJson } from '../support'

const SpendSaveClosed = ({ path }) => {
  const structuredDataForSpendSaveClosing = useStaticQuery(graphql`
    query structuredDataConfigForSpendSaveClosing {
      contentfulComponentStructuredDataConfig (
        page: { eq: "/" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
    }
  `)
  const structuredData = parseJson(structuredDataForSpendSaveClosing.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)

  useEffect(() => {
    navigate('/spendone-saveone-closed')
  }, [])

  return (
    <WithoutFooter>
      <Seo 
        title="Australia's Leading Digital Finance Platform"
        description="Get a simpler, faster & fairer deal on a personal loan with SocietyOne. With flexible interest rates & no ongoing fees, borrowing has never been easier."
        image 
        structuredData={structuredData}
        path={path}
      />
      <Spend1Save1Closed/>
      <Spend1Save1ClosedBody />
      <TAndC />
    </WithoutFooter>

  )
}


export default SpendSaveClosed
